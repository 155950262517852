@tailwind base;
@tailwind components;
@tailwind utilities;

.computerScreen {
  position: absolute;
  background: radial-gradient(#1e2326f3, #1e2326);
  background-color: rgba(0, 0, 0, 0);
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
}

.scanLines {
  position: absolute;
  background-image: -moz-repeating-linear-gradient(
    top,
    transparent 0,
    transparent 2px,
    rgba(6, 28, 23, 0.2) 2px,
    rgba(6, 28, 23, 0.2) 4px
  );
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  background-position-x: 0%;
  background-position-y: 0%;
}
